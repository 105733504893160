// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-slides-js": () => import("./../src/pages/slides.js" /* webpackChunkName: "component---src-pages-slides-js" */),
  "component---src-pages-takeaction-at-school-js": () => import("./../src/pages/takeaction/at-school.js" /* webpackChunkName: "component---src-pages-takeaction-at-school-js" */),
  "component---src-pages-takeaction-at-work-js": () => import("./../src/pages/takeaction/at-work.js" /* webpackChunkName: "component---src-pages-takeaction-at-work-js" */),
  "component---src-pages-takeaction-in-community-js": () => import("./../src/pages/takeaction/in-community.js" /* webpackChunkName: "component---src-pages-takeaction-in-community-js" */)
}

